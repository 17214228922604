<template>
    <div class="d-flex w-100 vs__dropdown-toggle m-0 p-0">
        <div
            v-if="multiple && selectedWeaponsIDs.length > 0"
            v-b-modal.weapons-modal-select
            class="w-100 text-left font-weight-normal px-1 badge-container d-flex align-items-center flex-wrap"
            variant="flat-secondary"
            plaintext
        >
            <b-badge
                v-for="(weaponName, i) in weaponNames"
                :key="i"
                variant="primary"
                class="badge-custom"
            >
                {{ weaponName }}
            </b-badge>
        </div>

        <b-button
            v-else
            v-b-modal.weapons-modal-select
            variant="flat-secondary"
            class="w-100 text-left font-weight-normal px-1"
            plaintext
        >
            {{ singleWeaponName }}
        </b-button>

        <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-light"
            class="btn-icon ml-50"
            @click="resetWeaponHandleClick"
        >
            <feather-icon icon="XIcon" />
        </b-button>

        <WeaponsModalSelect
            :value="selectedWeaponsIDs"
            :multiple="multiple"
            @change="handleWeaponSelect"
        />
    </div>
</template>

<script>
import {
    // BFormInput,
    BButton,
    BBadge,
} from 'bootstrap-vue';
import WeaponsModalSelect from '@/views/weapons/weapons-modal/WeaponsModalSelect.vue';
import Ripple from 'vue-ripple-directive';
import useWeapons from '@/views/weapons/useWeapons';

export default {
    components: {
        // BFormInput,
        BButton,
        BBadge,
        WeaponsModalSelect,
    },

    directives: {
        Ripple,
    },

    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            weaponNames: this.$t('ui.select'),
            selectedWeaponsIDs: [],
        };
    },

    computed: {
        weaponsGroups() {
            return this.$store.getters['app-weapons/GET_WEAPONS_GROUPS'];
        },

        singleWeaponName() {
            const { value } = this.$attrs;
            const defaultText = this.$t('ui.select');

            if (!Number.isInteger(value)) return defaultText;

            // eslint-disable-next-line no-restricted-syntax
            for (const group of this.weaponsGroups) {
                const matchedWeapon = group.weapons.find(
                    (weapon) => weapon.id === value
                );

                if (matchedWeapon) return matchedWeapon.name;
            }

            return defaultText;
        },
    },

    watch: {
        selectedWeaponsIDs(newSelectedWeaponsIDs) {
            this.weaponNames = newSelectedWeaponsIDs.map(
                (id) => this.getWeaponByID(id).name
            );

            if (this.weaponNames.length === 0)
                this.weaponNames = this.$t('ui.select');
        },
    },

    methods: {
        resetWeaponHandleClick() {
            let resetValue = null;

            if (this.multiple) {
                resetValue = [];
            }

            this.$emit('input', resetValue);
            this.weaponNames = this.$t('ui.select');
            this.selectedWeaponsIDs = [];
        },

        hideModal() {
            this.$bvModal.hide('weapons-modal-select');
        },

        handleSelectSingle(id) {
            const weapon = this.getWeaponByID(id);

            this.$emit('input', id);
            this.weaponNames = weapon.name;

            this.hideModal();
        },

        handleSelectMultiple(arrayOfIDs) {
            this.selectedWeaponsIDs = arrayOfIDs;

            this.$emit('input', [...this.selectedWeaponsIDs]);
            this.hideModal();
        },

        handleWeaponSelect(payload) {
            if (this.multiple) {
                this.handleSelectMultiple(payload);

                return;
            }

            this.handleSelectSingle(payload);
        },
    },

    setup() {
        const { getWeaponByID } = useWeapons();

        return {
            // Methods
            getWeaponByID,
        };
    },
};
</script>

<style lang="scss" scoped>
.badge-container {
    margin-left: -12px;
    padding-bottom: 3px;
}

.badge-custom {
    margin: 5px 2px 2px 5px;
    border-radius: 3px;
    font-weight: 400;
}
</style>
