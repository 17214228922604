<template>
    <div>
        <b-row class="d-flex justify-content-between">
            <b-col cols="12" class="d-flex align-items-center">
                <h2 class="m-0">
                    <slot name="title"> Edit game items </slot>
                </h2>
            </b-col>
        </b-row>

        <!-- Spinner -->
        <div v-if="isLoading" class="centered">
            <b-spinner variant="primary" />
        </div>

        <b-row v-else class="d-flex justify-content-center">
            <b-col cols="12">
                <b-card class="mt-2">
                    <b-row>
                        <b-col cols="12" xl="4">
                            <!-- Game Items edit image -->
                            <game-items-edit-image
                                :game-item="gameItem"
                                :game-item-id="gameItemId"
                                :game-item-variant="gameItemVariant"
                                @refresh="
                                    fetchGameItemById({
                                        gameItemId,
                                    })
                                "
                            />
                        </b-col>

                        <b-col cols="12" xl="4">
                            <!-- Game Items edit form -->
                            <game-items-edit-form
                                :game-item="gameItem"
                                :game-item-id="gameItemId"
                                :game-item-variant="gameItemVariant"
                                @updated="handleGameItemUpdate"
                                @refresh="
                                    fetchGameItemById({
                                        gameItemId,
                                    })
                                "
                            />
                        </b-col>

                        <b-col cols="12" xl="4" class="d-flex flex-column">
                            <!-- File list -->
                            <game-items-edit-file-list
                                :files-list="gameItemFiles"
                                :game-item-variant="gameItemVariant"
                            />

                            <!-- File drop zone -->
                            <game-items-edit-upload-file
                                :game-item-id="gameItemId"
                                class="mt-auto mb-xl-4"
                                :game-item-variant="gameItemVariant"
                                @refresh="
                                    fetchGameItemById({
                                        gameItemId,
                                    })
                                "
                            />
                        </b-col>
                    </b-row>

                    <!-- Spacer -->
                    <hr class="spacing" />

                    <b-card-text class="text-right text-muted">
                        <span>
                            {{ $t('ui.lastUpdate') + ' ' }}
                        </span>

                        <!-- Last update -->
                        <live-date :date-string="gameItem.updated_at" />
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BSpinner, BCard, BRow, BCol, BCardText } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import LiveDate from '@/layouts/components/LiveDate.vue';
import useGameItemsEdit from './useGameItemsEdit';
import GameItemsEditFileList from './GameItemsEditFileList.vue';
import GameItemsEditUploadFile from './GameItemsEditUploadFile.vue';
import GameItemsEditForm from './GameItemsEditForm.vue';
import GameItemsEditImage from './GameItemsEditImage.vue';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BCardText,

        GameItemsEditFileList,
        GameItemsEditUploadFile,
        LiveDate,
        GameItemsEditForm,
        GameItemsEditImage,
    },

    directives: {
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },
    },

    data() {
        return {
            isLoading: true,
        };
    },

    computed: {
        gameItemId() {
            let { id } = this.$route.params;

            try {
                id = Number.parseInt(id, 10);
            } catch (error) {
                id = null;
            }

            return id;
        },
    },

    created() {
        this.fetchGameItemById({
            gameItemId: this.gameItemId,
        }).finally(() => {
            this.isLoading = false;
        });
    },

    methods: {
        handleGameItemUpdate(updatedGameItem) {
            this.assignGameItem(updatedGameItem);
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const {
            gameItem,
            gameItemFiles,
            fetchGameItemById,
            assignGameItem,
        } = useGameItemsEdit({ gameItemVariant });

        return {
            // Reactive data
            gameItem,
            gameItemFiles,

            // Methods
            fetchGameItemById,
            assignGameItem,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@/utils/scss/vue-select-rarity.scss';

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
