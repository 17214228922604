export const isObjectAPromise = (value) => {
    return typeof value?.then === 'function';
};

export const parseJSON = (json = '') => {
    try {
        return JSON.parse(json);
    } catch (_) {
        return null;
    }
};

export const _ = {};
