import store from '@/store';
import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

import { ref, reactive, toRefs } from '@vue/composition-api';

export default function useGameItems({ gameItemVariant }) {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);

    const formSettingsVariants = {
        skins: {
            showInnerName: false,
            showCustomSwitch: true,
            showWeaponSelector: true,
            showImageUrl: true,
            rarityDependsOnCustom: true,
            showDeleteFileButton: true,
            acceptAllFiles: true,
        },
        models: {
            showInnerName: true,
            showCustomSwitch: false,
            showWeaponSelector: true,
            showImageUrl: true,
            rarityDependsOnCustom: true,
            showDeleteFileButton: false,
            acceptAllFiles: false,
        },
        characters: {
            showInnerName: true,
            showCustomSwitch: false,
            showWeaponSelector: false,
            showImageUrl: true,
            rarityDependsOnCustom: true,
            showDeleteFileButton: false,
            acceptAllFiles: false,
        },
        skybox: {
            showInnerName: true,
            showCustomSwitch: true,
            showWeaponSelector: false,
            showImageUrl: false,
            rarityDependsOnCustom: false,
            showDeleteFileButton: false,
            acceptAllFiles: false,
        },
    };

    const getFormSetting = (gameItem) =>
        formSettingsVariants[gameItem] ?? formSettingsVariants.default;

    const formSetting = reactive({
        ...getFormSetting(gameItemVariant),
    });

    const searchQuery = ref('');
    const currentPage = ref(1);
    const perPage = ref(69);
    const skinSetFilter = ref([]);
    const weaponsFilter = ref([]);
    const raritiesFilter = ref([]);
    const customFilter = ref(null);
    const legalFilter = ref(null);
    const gameItemName = ref('');
    const gameItemInnerName = ref('');
    const weaponID = ref(null);
    const rarityID = ref(null);
    const isLegal = ref(false);
    const isCustom = ref(gameItemVariant !== 'skins');
    const isPublic = ref(true);
    const imageURL = ref('');
    const selectedWeapon = ref({});

    const inputs = reactive({
        gameItemNameOptions: {
            maxlength: 128,
        },
    });

    const skinSetOptions = reactive([
        {
            id: 1,
            label: 'Supreme',
            value: 1,
        },

        {
            id: 2,
            label: 'Neon Rider',
            value: 2,
        },
    ]);

    const fetchGameItems = ({ limit = null, page = null }) => {
        return store
            .dispatch('app-game-items/fetchGameItems', {
                gameItemVariant,
                locale: i18nReactive.locale,
                limit,
                page,
            })
            .then((response) => {
                console.log('response', response);
            })
            .catch(showErrorMessage);
    };

    const paginationHandleChange = async (page) => {
        await fetchGameItems({ limit: perPage.value, page });
    };

    const postNewGameItem = ({
        gameItemData,
        locale = i18nReactive.locale,
    }) => {
        return store
            .dispatch('app-game-items/postNewGameItem', {
                gameItemVariant,
                gameItemData,
                locale,
            })
            .catch(showErrorMessage);
    };

    return {
        // Reactive data
        searchQuery,
        currentPage,
        perPage,
        skinSetFilter,
        weaponsFilter,
        raritiesFilter,
        customFilter,
        legalFilter,
        skinSetOptions,
        ...toRefs(inputs),

        ...toRefs(formSetting),
        gameItemName,
        gameItemInnerName,
        weaponID,
        isLegal,
        isCustom,
        isPublic,
        imageURL,
        rarityID,
        selectedWeapon,

        // Methods
        fetchGameItems,
        paginationHandleChange,
        postNewGameItem,
    };
}
