<template>
    <div>
        <h6 class="font-weight-bold">
            {{ $t('ui.fileList') }}
        </h6>

        <b-card-text
            v-if="filesListLocal.length === 0"
            class="mt-2 text-center text-muted"
        >
            {{ $t('ui.noFiles') }}
        </b-card-text>

        <ul v-else class="m-0 p-0">
            <li
                v-for="(file, index) in filesListLocal"
                :key="file.code"
                class="d-flex align-items-center m-0"
            >
                <span class="font-weight-bold mr-50">
                    {{ file.name }}
                </span>

                <span class="mr-50">
                    {{ getFormatedBytes(file.size) }}
                </span>

                <span class="mr-50">
                    {{ file.type }}
                </span>

                <b-button
                    v-if="showDeleteFileButton"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-secondary"
                    class="btn-icon ml-auto"
                    @click="openConfirmModal(file.id, index)"
                >
                    <feather-icon icon="TrashIcon" />
                </b-button>
            </li>
        </ul>

        <modals-confirm-action
            modal-i-d="modalDeleteFile"
            @cancel="resetSelectedFileID"
            @submit="deleteFile"
        >
            <template #title>
                {{ $t('gameItem.fileWillBeDeleted') }}
            </template>

            <template #message>
                {{ $t('ui.areYouSure') }}
            </template>
        </modals-confirm-action>
    </div>
</template>

<script>
import { BButton, BCardText } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ModalsConfirmAction from '@/views/components/modals/ModalsConfirmAction.vue';
import { getFormatedBytes } from '@/utils/formatter';
import usePositionedToast from '@/utils/usePositionedToast';
import useGameItems from '../useGameItems';
import useGameItemsEdit from './useGameItemsEdit';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        BButton,
        BCardText,

        ModalsConfirmAction,
    },

    directives: {
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },

        filesList: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            filesListLocal: [...this.filesList],
            selectedFileID: null,
        };
    },

    watch: {
        filesList(newValue) {
            this.filesListLocal = [...newValue];
        },
    },

    methods: {
        openConfirmModal(fileID, index) {
            this.selectedFileID = fileID;
            this.selectedFileLocalIndex = index;

            this.$bvModal.show('modalDeleteFile');
        },

        resetSelectedFileID() {
            this.selectedFileID = null;
            this.selectedFileLocalIndex = null;
        },

        deleteFileFromLocalList() {
            this.filesListLocal.splice(this.selectedFileLocalIndex, 1);
        },

        deleteFile() {
            this.deleteGameItemFileById({ fileID: this.selectedFileID })
                .then((response) => {
                    this.deleteFileFromLocalList();

                    this.showNotification({ title: response?.data?.message });
                })
                .finally(() => {
                    this.resetSelectedFileID();
                });
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { showNotification } = usePositionedToast();
        const { showDeleteFileButton } = useGameItems({
            gameItemVariant,
        });
        const { deleteGameItemFileById } = useGameItemsEdit({
            gameItemVariant,
        });

        return {
            showDeleteFileButton,

            // Methods
            deleteGameItemFileById,

            // External methods
            showNotification,
            getFormatedBytes,
        };
    },
};
</script>

<style lang="scss" scoped></style>
