import store from '@/store';
import i18n from '@/libs/i18n/index';
import { reactive, set } from '@vue/composition-api';
import { getFormDataFormObject } from '@/utils/formatter';
import useHandleError from '@/utils/useHandleError';
import { getParamsByGameItemsVariant } from '../gameItemsUtils';

export default function useGameItemsEdit({ gameItemVariant }) {
    const { showErrorMessage } = useHandleError();

    const gameItem = reactive({
        created_at: null,
        image_url: null,
        is_custom: null,
        is_legal: null,
        is_public: null,
        name: null,
        rarity_id: null,
        updated_at: null,
        weapon_id: null,
    });
    const gameItemFiles = reactive([]);
    const i18nReactive = reactive(i18n);

    const assignGameItem = (data) => {
        Object.keys(data).forEach((key) => {
            if (key === 'files') return;

            set(gameItem, key, data[key]);
        });
    };

    const assignGameItemsFiles = (data) => {
        const arrLength = gameItemFiles.length;

        gameItemFiles.splice(0, arrLength, ...data.files);
    };

    const fetchGameItemById = ({ gameItemId }) => {
        return store
            .dispatch('app-game-items/fetchGameItemById', {
                gameItemVariant,
                locale: i18nReactive.locale,
                gameItemId,
            })
            .then((responce) => {
                assignGameItem(responce.data);
                assignGameItemsFiles(responce.data);
            })
            .catch(showErrorMessage);
    };

    const editGameItemById = ({ gameItemId, updatedGameItem }) => {
        return store
            .dispatch('app-game-items/editGameItemById', {
                gameItemVariant,
                locale: i18nReactive.locale,
                gameItemId,
                updatedGameItem,
            })
            .catch(showErrorMessage);
    };

    const uploadGameItemFileById = ({ fileData }) => {
        const bodyFormData = getFormDataFormObject(fileData);

        return store
            .dispatch('app-game-items/uploadGameItemFileById', {
                gameItemVariant,
                locale: i18nReactive.locale,
                bodyFormData,
            })
            .catch(showErrorMessage);
    };

    const deleteGameItemFileById = ({ fileID }) => {
        if (!fileID)
            return Promise.reject(
                new Error(i18nReactive.t('gameItem.noFileID'))
            ).catch(showErrorMessage);

        return store
            .dispatch('app-game-items/deleteGameItemFileById', {
                gameItemVariant,
                locale: i18nReactive.locale,
                fileID,
            })
            .catch(showErrorMessage);
    };

    const uploadGameItemImageById = ({ image, gameItemId }) => {
        const gameItemParams = getParamsByGameItemsVariant(gameItemVariant);
        const formData = {
            image,
        };

        formData[gameItemParams.id] = gameItemId;

        const bodyFormData = getFormDataFormObject(formData);

        return store
            .dispatch('app-game-items/uploadGameItemImageById', {
                gameItemVariant,
                locale: i18nReactive.locale,
                bodyFormData,
            })
            .catch(showErrorMessage);
    };

    return {
        // Reactive data
        gameItem,
        gameItemFiles,

        // Methods
        fetchGameItemById,
        editGameItemById,
        uploadGameItemFileById,
        deleteGameItemFileById,
        uploadGameItemImageById,

        assignGameItem,
        assignGameItemsFiles,
    };
}
