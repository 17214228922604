<template>
    <div>
        <div class="img-wrapper">
            <img
                v-if="gameItem.image_url"
                class="img-content"
                :src="gameItem.image_url"
                alt="Game item image"
            />

            <span v-else class="centered">
                <feather-icon class="mr-25" icon="ImageIcon" />
                {{ $t('ui.noImage') }}
            </span>
        </div>

        <b-button
            v-b-modal.imageFileModal
            :disabled="isLoading"
            variant="primary"
            class="mt-1"
            >{{ $t('gameItem.uploadImage') }}</b-button
        >

        <b-modal
            id="imageFileModal"
            centered
            :title="$t('gameItem.uploadImage')"
            @hide="validateImageFile"
        >
            <span class="mt-1">
                {{ imageResultionsMessage }}
            </span>

            <b-form-file
                v-model="imageFile"
                class="mt-1"
                :accept="imageFormats"
            />

            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-danger" @click="cancel()">
                    {{ $t('ui.cancel') }}
                </b-button>

                <b-button
                    :disabled="!imageFile"
                    variant="primary"
                    @click="ok()"
                >
                    {{ $t('ui.upload') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { BButton, BModal, VBModal, BFormFile } from 'bootstrap-vue';
import usePositionedToast from '@/utils/usePositionedToast';
import { parseJSON } from '@/utils/utils';
import { isImageResolutionValid } from '@/utils/image';
import useGameItemsEdit from './useGameItemsEdit';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    directives: {
        'b-modal': VBModal,
    },

    components: {
        BButton,
        BModal,
        BFormFile,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },

        gameItemId: {
            type: Number,
            required: true,
        },

        gameItem: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            imageFile: null,
        };
    },

    computed: {
        imageFormats() {
            const imageFormats =
                parseJSON(process.env.VUE_APP_SKIN_IMAGE_FORMATS) || null;

            return imageFormats?.join(', ') || 'image/png';
        },

        imageResolutions() {
            return parseJSON(process.env.VUE_APP_SKIN_IMAGE_RESOLUTIONS) || [];
        },

        imageResultionsMessage() {
            if (!this.imageResolutions?.length) {
                return '';
            }

            const resolutions = this.imageResolutions
                .map((imageResolution) => {
                    return `${imageResolution.width} x ${imageResolution.height}`;
                })
                .join(', ');

            return `${this.$t(
                'gameItem.acceptableResolutions'
            )}: ${resolutions}px`;
        },
    },

    methods: {
        getHeightAndWidthFromDataUrl(dataURL) {
            return new Promise((resolve) => {
                const img = new Image();

                img.onload = () => {
                    resolve({
                        height: img.height,
                        width: img.width,
                    });
                };
                img.src = dataURL;
            });
        },

        async validateImageFile(event) {
            const { trigger = null } = event;

            if (trigger !== 'ok') {
                this.imageFile = null;

                return;
            }

            const isImageValid = await isImageResolutionValid({
                file: this.imageFile,
                resolutions: this.imageResolutions,
            });

            if (isImageValid) {
                this.changeImageFile();
                return;
            }

            this.showNotification({
                title: this.imageResultionsMessage,
                variant: 'danger',
            });
        },

        changeImageFile() {
            this.isLoading = true;

            this.uploadGameItemImageById({
                image: this.imageFile,
                gameItemId: this.gameItemId,
            })
                .then((response) => {
                    this.showNotification({
                        title: response?.data?.message,
                        variant: 'success',
                    });

                    this.$emit('refresh');
                })
                .catch((error) => {
                    const message = error?.response?.data?.errors?.image;

                    if (!message) {
                        return;
                    }

                    this.showNotification({
                        title: message,
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    this.imageFile = null;
                    this.isLoading = false;
                });
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { uploadGameItemImageById } = useGameItemsEdit({
            gameItemVariant,
        });
        const { showNotification } = usePositionedToast();

        return {
            // Methods
            uploadGameItemImageById,
            showNotification,
        };
    },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
    position: relative;
    padding-bottom: 75%;

    .img-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
