<template>
    <b-modal :id="modalID" no-body centered @hide="modalHandleCancel">
        <template #modal-title>
            <slot name="title">{{ $t('ui.confirmAction') }}</slot>
        </template>

        <slot name="message"></slot>

        <template #modal-footer="{ cancel }">
            <b-button
                variant="primary"
                class="mr-50"
                :disabled="isLoading"
                @click="modalHandleSubmit"
            >
                <slot name="ok">{{ $t('ui.yes') }}</slot>
            </b-button>

            <b-button
                variant="outline-secondary"
                :disabled="isLoading"
                @click="cancel()"
            >
                <slot name="cancel">{{ $t('ui.no') }}</slot>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';

export default {
    components: {
        BModal,
        BButton,
    },

    props: {
        modalID: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        modalHandleSubmit() {
            this.$emit('submit');

            this.$bvModal.hide(this.modalID);
        },

        modalHandleCancel(event) {
            if (event.trigger === 'event' || event.trigger === 'ok') return;

            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss" scoped></style>
