<template>
    <validation-observer ref="editGameItem" #default="{ invalid }" slim>
        <b-form @submit.prevent>
            <!-- Game Item name -->
            <b-form-group
                id="game-item-name-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.name')"
            >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('gameItem.name')"
                    :rules="`required|max:${gameItemNameOptions.maxlength}`"
                >
                    <b-form-input
                        id="game-item-name-input"
                        v-model.trim="gameItemLocal.name"
                        required
                        :maxlength="gameItemNameOptions.maxlength"
                        :name="$t('gameItem.name')"
                        width="100"
                    />

                    <small class="text-danger">
                        {{ errors[0] }}
                    </small>
                </validation-provider>
            </b-form-group>

            <!-- Game Item Inner name -->
            <b-form-group
                v-if="showInnerName"
                id="game-item-inner-name-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.innerName')"
            >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('gameItem.innerName')"
                    :rules="`required|max:${gameItemNameOptions.maxlength}`"
                >
                    <b-form-input
                        id="game-item-name-input"
                        v-model.trim="gameItemLocal.inner_name"
                        required
                        :maxlength="gameItemNameOptions.maxlength"
                        :name="$t('gameItem.innerName')"
                        width="100"
                    />

                    <small class="text-danger">
                        {{ errors[0] }}
                    </small>
                </validation-provider>
            </b-form-group>

            <!-- Weapon select -->
            <b-form-group
                v-if="showWeaponSelector"
                id="weapon-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.weapon')"
                class="mt-2"
            >
                <validation-provider
                    :name="$t('gameItem.weapon')"
                    rules="required"
                    slim
                >
                    <weapons-select-single v-model="gameItemLocal.weapon_id" />
                </validation-provider>
            </b-form-group>

            <!-- Rarity select -->
            <b-form-group
                id="rarity-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.rarity')"
                class="mt-2"
            >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('gameItem.rarity')"
                    rules="required"
                >
                    <v-select
                        v-model="gameItemLocal.rarity_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="filteredRarities"
                        class="select w-100"
                        label="name"
                        :reduce="(rarity) => rarity.id"
                        required
                        width="100"
                    >
                        <template v-slot:option="option">
                            <span
                                class="select__circle"
                                :style="`background-color: ${option.main_color}`"
                            />
                            {{ option.name }}
                        </template>
                    </v-select>

                    <small class="text-danger">
                        {{ errors[0] }}
                    </small>
                </validation-provider>
            </b-form-group>

            <!-- Legal switch -->
            <b-form-group
                id="is-legal-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.isLegal')"
                class="mt-2"
            >
                <label
                    class="mr-50 mb-0"
                    @click="gameItemLocal.is_legal = !gameItemLocal.is_legal"
                >
                    {{ $t('ui.no') }}
                </label>

                <b-form-checkbox
                    v-model="gameItemLocal.is_legal"
                    if="is-legal-switch"
                    name="check-button"
                    switch
                    inline
                >
                    {{ $t('ui.yes') }}
                </b-form-checkbox>
            </b-form-group>

            <!-- Public switch -->
            <b-form-group
                v-if="gameItemLocal.is_custom"
                id="is-public-fieldset"
                label-class="font-weight-bold"
                :label="$t('gameItem.isPublic')"
                class="mt-2"
            >
                <label
                    class="mr-50 mb-0"
                    @click="gameItemLocal.is_public = !gameItemLocal.is_public"
                >
                    {{ $t('ui.no') }}
                </label>

                <b-form-checkbox
                    v-model="gameItemLocal.is_public"
                    name="check-button"
                    switch
                    inline
                >
                    {{ $t('ui.yes') }}
                </b-form-checkbox>
            </b-form-group>

            <!-- Submit button -->
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-2"
                variant="primary"
                type="submit"
                :disabled="invalid || isLoading"
                @click="editGameItemData"
            >
                <span v-if="isLoading" class="d-flex align-items-end">
                    <b-spinner variant="light" small class="mr-50" />

                    <span>
                        {{ $t('ui.loading') }}
                    </span>
                </span>

                <span v-else>
                    {{ $t('ui.edit') }}
                </span>
            </b-button>
        </b-form>
    </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max } from '@validations';
import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import WeaponsSelectSingle from '@/views/weapons/weapons-select/WeaponsSelectSingle.vue';

import useWeapons from '@/views/weapons/useWeapons';
import usePositionedToast from '@/utils/usePositionedToast';
import useHandleError from '@/utils/useHandleError';
import useGameItems from '../useGameItems';
import useGameItemsEdit from './useGameItemsEdit';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BButton,
        BSpinner,

        vSelect,

        WeaponsSelectSingle,
    },

    directives: {
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },

        gameItemId: {
            type: Number,
            required: true,
        },

        gameItem: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            gameItemLocal: { ...this.gameItem },
            isLoading: false,

            // validation rules
            required,
            max,
        };
    },

    computed: {
        rarities() {
            return this.$store.getters['app-rarities/GET_RARITIES'] || [];
        },

        filteredRarities() {
            if (this.rarityDependsOnCustom) {
                return this.rarities.filter(
                    (rarity) =>
                        rarity.is_custom === this.gameItemLocal.is_custom
                );
            }

            return this.rarities.filter((rarity) => rarity.is_custom);
        },
    },

    watch: {
        gameItemLocal: {
            deep: true,

            handler(gameItemLocal) {
                this.$emit('updated', { ...gameItemLocal });
            },
        },
    },

    methods: {
        collectGameItemData() {
            if (
                this.showWeaponSelector &&
                (this.gameItemLocal.weapon_id === null ||
                    this.gameItemLocal.weapon_id === undefined)
            ) {
                this.showNotification({
                    title: this.$t('gameItem.weaponNotSelected'),
                    variant: 'danger',
                });

                return Promise.reject(new Error('Validation error'));
            }

            return Promise.resolve(this.gameItemLocal);
        },

        editGameItemData() {
            this.isLoading = true;

            this.$refs.editGameItem
                .validate()
                .then((success) => {
                    if (success) {
                        return this.collectGameItemData();
                    }

                    return Promise.reject(new Error('Validation error'));
                })
                .then((updatedGameItem) => {
                    return this.editGameItemById({
                        gameItemId: this.gameItemId,
                        updatedGameItem,
                        locale: this.$i18n.locale,
                    });
                })
                .then((response) => {
                    this.showNotification({
                        title: response.data.message,
                        variant: 'success',
                    });

                    this.$emit('refresh');
                })
                .catch(this.showErrorMessage)
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { showNotification } = usePositionedToast();
        const { showErrorMessage } = useHandleError();
        const { getWeaponByID } = useWeapons();
        const {
            gameItemNameOptions,
            showInnerName,
            showWeaponSelector,
        } = useGameItems({
            gameItemVariant,
        });
        const { fetchGameItemById, editGameItemById } = useGameItemsEdit({
            gameItemVariant,
        });

        return {
            // Reactive data
            gameItemNameOptions,
            showInnerName,
            showWeaponSelector,

            // Methods
            getWeaponByID,
            fetchGameItemById,
            editGameItemById,

            showNotification,
            showErrorMessage,
        };
    },
};
</script>

<style lang="scss" scoped></style>
