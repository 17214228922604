/* eslint-disable no-restricted-syntax */
import store from '@/store';
import {
    ref,
    reactive,
    // watch
} from '@vue/composition-api';
import i18n from '@/libs/i18n/index';

// Notification
import useHandleError from '@/utils/useHandleError';

export default function useWeapons() {
    // Use toast
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);
    const locale = ref(i18nReactive.locale);

    const fetchWeaponsGroups = () => {
        return store
            .dispatch('app-weapons/fetchWeapons', { locale: locale.value })
            .catch(showErrorMessage);
    };

    const getWeaponsGroups = () => {
        return store.getters['app-weapons/GET_WEAPONS_GROUPS'];
    };

    const getWeaponByID = (id) => {
        const weaponsGroups = getWeaponsGroups();
        // eslint-disable-next-line guard-for-in
        for (const group of weaponsGroups) {
            const matchedWeapon = group.weapons.find(
                (weapon) => weapon.id === id
            );

            if (matchedWeapon) return matchedWeapon;
        }

        return {};
    };

    return {
        // Methods
        fetchWeaponsGroups,
        getWeaponsGroups,
        getWeaponByID,
    };
}
