<template>
    <b-modal
        :id="modalID"
        scrollable
        :hide-footer="!multiple"
        size="xl"
        body-bg-variant="light"
        @hide="handleHide($event)"
    >
        <div
            v-for="weaponGroup in weaponsGroups"
            :key="weaponGroup.name"
            class="mb-2"
        >
            <h4 class="mb-2">
                {{ weaponGroup.name }}
            </h4>

            <b-row>
                <b-col
                    v-for="weapon in weaponGroup.weapons"
                    :key="weapon.id"
                    cols="4"
                    md="2"
                    xl="1"
                    class="mb-1 px-50"
                >
                    <b-card
                        :img-src="weapon.image_url"
                        :img-alt="weapon.name"
                        img-top
                        tag="article"
                        no-body
                        class="weapon-card h-100 m-0 p-50"
                        :class="{
                            'bg-primary bg-lighten-1 text-white': selectedWeaponsIDs.includes(
                                weapon.id
                            ),
                        }"
                        @click="cardHandleClick(weapon)"
                    >
                        <b-card-body class="p-0 mt-1">
                            <b-card-text class="text-center">
                                {{ weapon.name }}
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <b-alert v-if="weaponsGroups.length === 0" variant="danger" show>
            <div class="alert-body">
                <span>
                    {{ $t('weapons.failedToFetch') }}
                </span>
            </div>
        </b-alert>

        <template #modal-footer>
            <b-button variant="outline-secondary" @click="resetHandleClick">
                {{ $t('ui.reset') }}
            </b-button>

            <b-button variant="primary" @click="confirmHandleClick">
                {{ $t('ui.confirm') }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import {
    BModal,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BAlert,
    BButton,
} from 'bootstrap-vue';

export default {
    components: {
        BModal,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BAlert,
        BButton,
    },

    props: {
        multiple: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            modalID: 'weapons-modal-select',
            selectedWeaponsIDs: this.value,
        };
    },

    computed: {
        weaponsGroups() {
            return this.$store.getters['app-weapons/GET_WEAPONS_GROUPS'];
        },
    },

    watch: {
        value(value) {
            this.selectedWeaponsIDs = value;
        },
    },

    methods: {
        toggleIdInArray(id) {
            const indexOfMatchedID = this.selectedWeaponsIDs.indexOf(id);

            if (indexOfMatchedID === -1) {
                this.selectedWeaponsIDs.push(id);
                return;
            }

            this.selectedWeaponsIDs.splice(indexOfMatchedID, 1);
        },

        cardHandleClick(weapon) {
            if (this.multiple) {
                this.toggleIdInArray(weapon.id);

                return;
            }

            this.$emit('change', weapon.id);
        },

        emitChangeEvent() {
            this.$emit('change', this.selectedWeaponsIDs);
        },

        confirmHandleClick() {
            this.emitChangeEvent();
        },

        resetHandleClick() {
            this.selectedWeaponsIDs = [];
            this.emitChangeEvent();
        },

        handleHide(event) {
            if (event.trigger === 'event' || !this.multiple) return;

            this.$emit('change', this.selectedWeaponsIDs);
        },
    },
};
</script>

<style lang="scss" scoped>
.weapon-card {
    cursor: pointer !important;
    box-shadow: inset 0px 0px 0px 1px rgba(115, 103, 240, 0);
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: inset 0px 0px 0px 2px rgba(115, 103, 240, 1);
        transform: translateY(-10px);
    }
}

.weapon-card_active {
    background-color: rgba(115, 103, 240, 1);
    color: #fff;
}
</style>
