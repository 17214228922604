export const getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
        const img = new Image();

        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width,
            });
        };
        img.src = dataURL;
    });

export const getFileAsDataURL = (file) => window.URL.createObjectURL(file);

export const isImageResolutionValid = async ({ file, resolutions = [] }) => {
    if (!resolutions?.length) return true;

    const fileAsDataURL = window.URL.createObjectURL(file);
    const imageResolution = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    const matchedDimensions = resolutions.find((dimension) => {
        return (
            dimension.width === imageResolution.width &&
            dimension.heigth === imageResolution.heigth
        );
    });

    console.log('matchedDimensions', matchedDimensions);

    return !!matchedDimensions;
};

export const _ = {};
