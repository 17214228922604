export const transactionStatuses = [
    {
        id: 1,
        name: 'new',
        variant: 'secondary',
    },
    {
        id: 2,
        name: 'canceled',
        variant: 'danger',
    },
    {
        id: 3,
        name: 'declined',
        variant: 'warning',
    },
    {
        id: 4,
        name: 'authorized',
        variant: 'primary',
    },
    {
        id: 5,
        name: 'completed',
        variant: 'success',
    },
];
/**
 * @param {Array.<String>} arrOfSuccessStatusNames - Array of success status names
 * @returns {Array.<Number>} array of success status IDs
 */
export const getTransactionSuccessStatusesIDs = (
    arrOfSuccessStatusNames = []
) => {
    const matchedStatuses = transactionStatuses.filter((status) =>
        arrOfSuccessStatusNames.includes(status.name)
    );

    if (matchedStatuses.length) {
        return matchedStatuses.map((status) => status.id);
    }

    return [];
};

export const _ = {};
