/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { transactionStatuses } from '@/utils/transactions-statuses';
import { DateTime, Settings } from 'luxon';
import i18n from '@/libs/i18n/index';

export const zoneName = 'utc';

Settings.defaultZoneName = zoneName;

/**
 * @param  {Object} subscription Subscription object
 * @param  {boolean} subscription.is_active Defines if the subscription is currently active
 * @param  {boolean} subscription.auto_replenish Defines if auto-renewal of payments is active
 * @returns {string} Readable text for subscription badge
 */
export const subscriptionBadgeText = (subscription = null) => {
    if (!subscription) return 'None';

    if (!subscription.is_active && subscription.auto_replenish)
        return 'Auto-Renewal is Active';

    if (subscription.is_active && !subscription.auto_replenish)
        return 'Subscription is Active';

    if (subscription.is_active && subscription.auto_replenish)
        return 'All Active';

    return 'None';
};

/**
 * The function localizes the date
 * @param {string} value string, date in format 'YYYY-MM-DDThh:mm:ss'
 * @param {string} locale string, locale ISO 639-1 code
 * @param {object} formatting object, localization options
 * @returns {string} string, localized date
 */
export const formatDate = (
    value,
    locale = 'en',
    formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
    if (!value) return value;

    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value));
};

/**
 * Slices first two characters of string.
 * @param {string} value - The title of the book.
 * @returns {string} string, first two characters.
 */
export const avatarText = (value) => {
    if (!value) return '';
    if (typeof value !== 'string') return '';

    return value.slice(0, 2);
};

/**
 * Defines currency symbol based on passed currency ISO 4217 code.
 * @param {string} currency
 * @returns {string}
 */
export const currencySymbol = (currency) => {
    switch (currency) {
        case 'RUB':
            return '₽';
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        default:
            return '';
    }
};

export const paymentsStatusText = (status) => {
    if (!status) return '';

    return status.name;
};

/**
 * Returns bootstrap's variant based on passed ID of transaction
 * @param  {string} statusID string, 'id' of status
 * @returns {string} string, bootstrap's variant
 */
export const resolvePaymentStatusVariantByID = (statusID) => {
    const matchedStatus = transactionStatuses.find(
        (status) => status.id === statusID
    );

    if (matchedStatus) return matchedStatus.variant;

    return 'secondary';
};

/**
 * Returns masked card number
 * @param  {string} card card number
 * @returns {string} string, in format dddddd **** dddd
 */
export const paymentsCardMask = (card) => {
    if (!card) return '';

    return `${card.slice(0, 6)} **** ${card.slice(-4)}`;
};

/**
 * Returns credit card name
 * @param  {string} cardName string, type of payment card
 * @returns {string} 'require' with webpack path to one of available paycard image, or just 'credit-card' as fallback
 */
export const getCreditCardLogoName = (cardName = '') => {
    const path = 'assets/images/icons/paycard';
    const urlFallback = require(`@/${path}/credit-card.svg`);

    if (typeof cardName !== 'string') {
        return urlFallback;
    }

    try {
        return require(`@/${path}/${cardName.toLowerCase()}.svg`);
    } catch (error) {
        return urlFallback;
    }
};

export const getIntegerFloor = (num) => Math.floor(num);

export const getLiveDate = (dateStringISO) => {
    const lastUpdateDate = DateTime.fromISO(dateStringISO);
    const currentDate = DateTime.now();
    const options = ['days', 'hours', 'minutes'];

    const { values = {} } = currentDate.diff(lastUpdateDate, options);
    const { locale = 'en' } = i18n;
    const ago = ` ${i18n.tc('date.ago')}`;

    if (values.days)
        return lastUpdateDate.setLocale(locale).toFormat('dd MMMM yyyy, HH:mm');

    if (values.hours) {
        const hours = getIntegerFloor(values.hours);

        return `${i18n.tc('date.hours', hours)}${ago}`;
    }

    if (values.minutes > 1) {
        const minutes = getIntegerFloor(values.minutes);

        return `${i18n.tc('date.minutes', minutes)}${ago}`;
    }

    return i18n.tc('date.now');
};

export const getMaxDate = () => {
    return DateTime.now().setZone(zoneName).toISODate();
};

export const getDateForDatePicker = (unit) => {
    const currentDate = DateTime.now();

    if (unit === 'yearly') return currentDate.toFormat('yyyy');
    if (unit === 'monthly') return currentDate.toFormat('yyyy-LL');
    if (unit === 'weekly') return currentDate.toISODate();

    return currentDate.minus({ days: 1 }).toISODate();
};

export const getTimeZoneString = () => {
    return DateTime.now().toFormat('z');
};

export const getFormattedDateRange = (startDateSting, endDateString) => {
    if (!startDateSting || !endDateString) return '';

    const { locale = 'en' } = i18n;
    const startDate = DateTime.fromISO(startDateSting).setLocale(locale);
    const endDate = DateTime.fromISO(endDateString).setLocale(locale);

    return `
        ${startDate.toFormat('dd.LL.yyyy')} - ${endDate.toFormat('dd.LL.yyyy')}
        `;
};

export const getDateOffset = (options = { days: 3 }) => {
    return DateTime.now().minus(options).toFormat('y-LL-dd');
};

export const getFromDateAndTime = (options = { days: 3 }) => {
    return DateTime.now().minus(options).toFormat('y-LL-dd 00:00:00');
};

export const getToDateAndTime = (options = { days: 1 }) => {
    return DateTime.now().minus(options).toFormat('y-LL-dd 23:59:59');
};

export const getFormattedBankCardDate = (date) => {
    if (!date) return '';

    return DateTime.fromISO(date).toFormat('LL/yy');
};

/**
 * Functions transforms regular object to form data
 * @param {Object} object
 * @returns FormData with keys and values of passed object
 */
export const getFormDataFormObject = (obj = {}) => {
    const bodyFormData = new FormData();

    Object.keys(obj).forEach((key) => {
        bodyFormData.append(key, obj[key]);
    });

    return bodyFormData;
};

export const getFormattedThousands = (num = 0, sepatator = ' ') => {
    if (typeof num !== 'number' || Number.isNaN(num)) return num;

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sepatator);
};

export const getFormatedBytes = (bytes, decimals = 2) => {
    if (!bytes) return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getPercentage = (partialValue, totalValue) => {
    if (
        typeof partialValue !== 'number' ||
        typeof totalValue !== 'number' ||
        !totalValue
    )
        return null;

    const percentage = (100 * partialValue) / totalValue;

    return Math.round(percentage * 100) / 100;
};
