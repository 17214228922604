<template>
    <div>
        <validation-observer ref="uploadFileForm" slim>
            <b-form class="mt-auto" @submit.prevent>
                <b-form-group
                    id="file-fieldset"
                    label-class="font-weight-bold"
                    :label="$t('ui.addFile')"
                >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('ui.file')"
                        rules="required"
                    >
                        <b-form-file
                            v-model="fileRaw"
                            :placeholder="$t('ui.chooseFile')"
                            :drop-placeholder="$t('ui.dropFile')"
                            :accept="acceptedFiles"
                            @input="fileHandleInput"
                        />

                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>

        <b-modal
            :id="fileTypeModalID"
            hide-footer
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            centered
            :title="$t('ui.chooseFileType')"
        >
            <validation-observer ref="pickFileType" #default="{ invalid }" slim>
                <validation-provider name="File type" rules="required">
                    <v-select
                        v-model="fileTypeCurrent"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="fileTypes"
                        class="select w-100"
                        label="name"
                        :reduce="(fileType) => fileType.id"
                        required
                        width="100"
                    />
                </validation-provider>

                <div class="d-flex mt-2">
                    <b-button
                        variant="primary"
                        class="mr-1"
                        :disabled="invalid"
                        @click="modalHandleSubmit"
                    >
                        <span
                            v-if="isFileLoading"
                            class="d-flex align-items-end"
                        >
                            <b-spinner variant="light" small class="mr-50" />

                            <span>
                                {{ $t('ui.loading') }}
                            </span>
                        </span>

                        <span v-else>
                            {{ $t('ui.upload') }}
                        </span>
                    </b-button>

                    <b-button
                        variant="outline-secondary"
                        @click="resetFileInput"
                    >
                        {{ $t('ui.cancel') }}
                    </b-button>
                </div>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { required } from '@validations';

import Ripple from 'vue-ripple-directive';

import usePositionedToast from '@/utils/usePositionedToast';
import useHandleError from '@/utils/useHandleError';
import useFiles from '@/views/files/useFiles';
import {
    BForm,
    BFormGroup,
    BFormFile,
    BButton,
    BSpinner,
    VBModal,
} from 'bootstrap-vue';
import useGameItems from '../useGameItems';
import useGameItemsEdit from './useGameItemsEdit';
import {
    gameItemsVariants,
    getParamsByGameItemsVariant,
} from '../gameItemsUtils';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormGroup,
        BFormFile,
        BButton,
        BSpinner,

        vSelect,
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },

        gameItemId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            isFileLoading: false,

            fileRaw: null,
            fileData: {},
            fileTypeModalID: 'file-type-modal',
            fileTypeCurrent: null,

            // validation rules
            required,
        };
    },

    computed: {
        fileTypes() {
            const fileTypes =
                this.$store.getters['app-files/GET_FILE_TYPES'] || [];

            if (!this.acceptAllFiles)
                return fileTypes.filter((fileType) => fileType.name === 'zip');

            return fileTypes;
        },

        acceptedFiles() {
            if (this.acceptAllFiles) return null;

            return this.fileTypes
                .map((fileType) => `.${fileType.name}`)
                .join(', ');
        },
    },

    methods: {
        modalHandleSubmit() {
            this.fileData.type_id = this.fileTypeCurrent;

            this.fileTypeCurrent = null;

            this.submitFileUpload();
        },

        resetFileInput() {
            this.$bvModal.hide(this.fileTypeModalID);
            this.fileData = null;
            this.fileTypeCurrent = null;
            this.fileRaw = null;
        },

        fileHandleInput(file) {
            if (file === null) return;

            const gameItemParams = getParamsByGameItemsVariant(
                this.gameItemVariant
            );

            this.fileData = {
                file,
            };

            this.fileData[gameItemParams.id] = this.gameItemId;

            if (this.acceptAllFiles) {
                this.$bvModal.show(this.fileTypeModalID);

                return;
            }

            this.fileData.type_id = this.fileTypes[0]?.id;

            this.submitFileUpload();
        },

        submitFileUpload() {
            this.isFileLoading = true;

            this.$refs.uploadFileForm
                .validate()
                .then(() => {
                    return this.uploadGameItemFileById({
                        fileData: this.fileData,
                    });
                })
                .then((response) => {
                    this.showNotification({
                        title: response.data.message,
                        variant: 'success',
                    });

                    this.resetFileInput();
                    this.$refs.uploadFileForm.reset();
                    this.$emit('refresh');
                })
                .finally(() => {
                    this.$bvModal.hide(this.fileTypeModalID);

                    this.isFileLoading = false;
                });
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { showNotification } = usePositionedToast();
        const { showErrorMessage } = useHandleError();
        const { acceptAllFiles } = useGameItems({ gameItemVariant });
        const { uploadGameItemFileById } = useGameItemsEdit({
            gameItemVariant,
        });
        const { fetchFileTypes } = useFiles();

        fetchFileTypes();

        return {
            acceptAllFiles,

            // Methods
            uploadGameItemFileById,

            // External Methods
            showNotification,
            showErrorMessage,
        };
    },
};
</script>

<style lang="scss" scoped></style>
