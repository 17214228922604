<template>
    <span>{{ dateText }}</span>
</template>

<script>
import { getLiveDate } from '@/utils/formatter';

export default {
    props: {
        dateString: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dateText: '',
        };
    },

    watch: {
        dateString() {
            this.updateDateText();
        },
    },

    created() {
        this.updateDateText();

        setTimeout(this.updateDateText, 1000 * 60);
    },

    methods: {
        updateDateText() {
            this.dateText = getLiveDate(this.dateString);
        },
    },
};
</script>

<style lang="scss" scoped></style>
