import { extend, localize } from 'vee-validate';
import {
    max as rule_max,
    numeric as rule_numeric,
} from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
import { getUserLanguage } from '@/libs/i18n/utils';

export const max = extend('max', rule_max);
export const numeric = extend('numeric', rule_numeric);
export * from '@/@core/utils/validations/validations';

// Installing locale
localize({
    ru: {
        messages: ru.messages,
        names: {
            email: 'Email',
            password: 'Пароль',
        },
        fields: {
            password: {
                min: '{_field_} слишком короткий',
            },
        },
    },
});

// Setting the locale
localize(getUserLanguage());
